import { Account, AppState } from './state';
import { ErrorResponse } from 'lib/types/accountType';
import { logError } from 'lib/utils/logReportHelper';
import {
  ActionType,
  AppActions,
  SetAccount,
  SetError,
  SetCodeInfo,
  SetFlowStage,
  SetQRCode,
  SetIsRegister,
  SetLoading,
  SetIsSocialAuth,
} from './actions';

export const reducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case ActionType.SetLoading: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionType.SetIsSocialAuth: {
      return {
        ...state,
        isSocialAuth: {
          data: action.payload,
          value: action.value,
          kind: action.kind,
        },
      };
    }
    case ActionType.SetFlowStage: {
      return {
        ...state,
        flowStage: action.payload,
      };
    }
    case ActionType.SetCodeInfo: {
      return {
        ...state,
        codeInfo: action.payload ?? {},
      };
    }
    case ActionType.SetQRCode: {
      return {
        ...state,
        qrCode: action.payload.toUpperCase(),
      };
    }
    case ActionType.SetAccount: {
      return {
        ...state,
        account: action.payload,
      };
    }
    case ActionType.SetLanguageStrings: {
      return {
        ...state,
        languageStrings: action.payload,
      };
    }
    case ActionType.SetError: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case ActionType.SetIsRegister: {
      return {
        ...state,
        isRegister: {
          value: action.value,
          account: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export const setLoading = (isLoading: boolean): SetLoading => ({
  type: ActionType.SetLoading,
  payload: isLoading,
});

export const setIsSocialAuth = (
  isSocialAuth: boolean,
  data?: AnyObject,
  kind?: string,
): SetIsSocialAuth =>
  <SetIsSocialAuth>{
    type: ActionType.SetIsSocialAuth,
    value: isSocialAuth,
    kind: kind,
    payload: data,
  };

export const setFlowStage = (flowStage: string): SetFlowStage => ({
  type: ActionType.SetFlowStage,
  payload: flowStage,
});

export const setAccount = (account: Account | undefined): SetAccount => ({
  type: ActionType.SetAccount,
  payload: account,
});

export const setQRCode = (qrCode: string): SetQRCode => ({
  type: ActionType.SetQRCode,
  payload: qrCode,
});

export const setCodeInfo = (codeInfo: AnyObject | undefined): SetCodeInfo => ({
  type: ActionType.SetCodeInfo,
  payload: codeInfo,
});

export const setError = (error: ErrorResponse | AnyObject | unknown): SetError => {
  const errorResponse: Error = error;
  if (error?.info) {
    const responseError = 'Error Boundary Reached';
    logError(error.error, { name: responseError });
  } else if (error && error?.name) {
    const responseError = error?.message || error?.name;
    logError(errorResponse, { name: responseError });
  }
  return {
    type: ActionType.SetError,
    payload: error,
  };
};

export const setIsRegister = (value: boolean | unknown, account?: AnyObject): SetIsRegister =>
  <SetIsRegister>{
    type: ActionType.SetIsRegister,
    payload: account,
    value,
  };
