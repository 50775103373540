import React, { ReactNode } from 'react';
import Head from 'next/head';
import { Brand } from 'constants/index';
import Header from '../Header';

interface Props {
  children?: ReactNode;
}

const Layout = ({ children }: Props): JSX.Element => {
  const BRAND = process.env.NEXT_PUBLIC_BRAND;
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Second Screen Activation | FOX Broadcasting Company</title>
        {BRAND !== Brand.NATION && (
          <>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`/favicons/${BRAND}/apple-icon-180x180.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={`/favicons/${BRAND}/favicon-32x32.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={`/favicons/${BRAND}/favicon-16x16.png`}
            />
          </>
        )}
        {BRAND === Brand.NATION && (
          <link rel="icon" type="image/png" sizes="16x16" href={`/favicons/${BRAND}/favicon.png`} />
        )}
      </Head>
      <Header />
      <main>{children}</main>
    </>
  );
};

export default Layout;
