import bg from '../images/brands/nation/background.png';

const theme = {
  sports: {
    brand: 'sports',
    colors: {
      header: 'linear-gradient(to right top, #0b1219 0%, #1b222a) fixed',
      body: 'linear-gradient(to right top, #000409,rgb(29 34 39 / 98%)) fixed',
      button: {
        text: '#FFFFFF',
        textHover: '#000000',
        background: '#0059B5',
        backgroundHover: '#FFFFFF',
        hollowBorder: '1px solid white',
        hollowBg: 'transparent',
      },
    },
  },
  fox: {
    brand: 'fox',
    colors: {
      header: 'linear-gradient(50deg, #2d536d 10%, #1b3140 40%)',
      body: '#1b3140 linear-gradient(144deg, #396d92, #1e3647 40%, rgba(27, 49, 64, 0) 53%) fixed',
      button: {
        text: '#FFFFFF',
        textHover: '#000000',
        background: '#0086bd',
        backgroundHover: '#FFFFFF',
        hollowBorder: '1px solid white',
        hollowBg: 'transparent',
      },
      Monza: '#d0021b',
      SanJuan: '#2d536d',
      BigStone: '#1b3140',
      FoxBlue: '#0299fe',
      altoLight: '#d8d8d8',
      bluePrimary: '#0086bd',
      chambray: '#2e4c83',
      sanMarino: '#4267b2',
      white25: 'gba(255, 255, 255, 0.25)',
      white50: 'gba(255, 255, 255, 0.5)',
      white10: 'gba(255, 255, 255, 0.1)',
      white05: 'gba(255, 255, 255, 0.05)',
      Alto: '#d8d8d8',
      grey25: 'gba(255, 255, 255, 0.25)',
      grey50: 'gba(255, 255, 255, 0.5)',
      darkGrey: '#252525',
      errorRed: '#d0021b',
      dustyGray: '#999',
      azureRadiance: '#007ee4',
    },
  },
  nation: {
    brand: 'nation',
    colors: {
      header: 'radial-gradient(ellipse 100% 0 at 100% 100%,#005fb9 1%,#152f52 100%)',
      body: `#004485 url(${bg}) top center no-repeat`,
      button: {
        text: '#000000',
        textHover: '#000000',
        background: '#a9b2b8',
        backgroundHover: '#FFFFFF',
        hollowBorder: '1px solid #004485',
        hollowBg: 'transparent',
        disabled: '#FFFFFF',
      },
      hitGray: '#a9b2b8',
    },
  },
};

export default theme;
