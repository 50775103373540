const color = {
  white: '#ffffff',
  black: '#000000',
  white25: 'rgba(255, 255, 255, 0.25)',
  transparent: 'transparent',
  error: '#d0021b',
  white10: 'rgba(255, 255, 255, 0.1)',
};

const fox = {
  white: color.white,
  black: color.black,
  Monza: '#d0021b',
  SanJuan: '#2d536d',
  BigStone: '#1b3140',
  FoxBlue: '#0299fe',
  altoLight: '#d8d8d8',
  bluePrimary: '#0086bd',
  chambray: '#2e4c83',
  sanMarino: '#4267b2',
  white25: 'rgba(255, 255, 255, 0.25)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white05: 'rgba(255, 255, 255, 0.05)',
  Alto: '#d8d8d8',
  grey25: 'rgba(255, 255, 255, 0.25)',
  grey50: 'rgba(255, 255, 255, 0.5)',
  darkGrey: '#252525',
  errorRed: '#d0021b',
  dustyGray: '#999',
  azureRadiance: '#007ee4',
  foxGradientBodyBackground:
    '#1b3140 linear-gradient(144deg, #396d92, #1e3647 40%, rgba(27, 49, 64, 0) 53%) fixed',
  header: 'linear-gradient(50deg, #2d536d 10%, #1b3140 40%) ',
  border: '1px solid #0086bd',
};

const sports = {
  header: 'linear-gradient(to right top, #0b1219 0%, #1b222a) fixed',
  sportsGradientBodyBackground: 'linear-gradient(to right top, #000409,rgb(29 34 39 / 98%)) fixed',
  endeavour: '#0059B5',
  border: '1px solid #0059B5',
};
const nation = {
  header: 'radial-gradient(ellipse 100% 0 at 100% 100%,#005fb9 1%,#152f52 100%)',
  nationBodyBackground: '#004485',
  hitGray: '#a9b2b8',
  border: '1px solid #004485',
};

export { fox, sports, nation };

export default color;
