export const ACCOUNT_COOKIE_NAME_FOX = 'ACTIVATE_ACCOUNT';
export const ACCOUNT_COOKIE_NAME_NATION = 'FOXSSOACCT';
export const COOKIE_CODE_INFO = 'code-info';
export const FLOW_STAGE_ENTER_REG_CODE = 'ENTER_REG_CODE';
export const FLOW_STAGE_GEN_REG_CODE = 'GEN_REG_CODE';
export const FLOW_STAGE_MVPD_SELECT = 'MVPD_SELECT';
export const FLOW_STAGE_PROFILE_AUTH = 'PROFILE_AUTH';
export const FLOW_STAGE_MVPD_AUTH = 'MVPD_AUTH';
export const FLOW_STAGE_MVPD_LINK = 'MVPD_LINK';
export const ANONYMOUS = 'anonymous';
export const COOKIE_CAMPAIGN_CODES = 'c_m';
export const INT_CMP = 'int_cmp';
export const CODE_TYPE_API = 'api';
export const ROUTE_HOME = '/';
export const ROUTE_MVPD = '/mvpd/';
export const ROUTE_SUCCESS = '/success/';
export const ROUTE_ACCOUNT = '/account/';
export const ROUTE_ACCOUNT_NATION = '/account/signin/tv/';
export const PATH_ACTIVATE = '/activate/';
export const REDIRECT_SECONDS = 5;
export const ACTIVATE_CODE_EXPIRES_MINUTES = 525600;
export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const DEBUG_PANEL_API_INTEGRATION = 'Integration';
export const DEBUG_PANEL_API_BASEURL_INT = 'https://int1.api3.fox.com/v2.0';
export const PREVIEW_PASS_5_MVPD = 'TempPass_fbcfox_5min';
export const PREVIEW_PASS_60_MVPD = 'TempPass_fbcfox_60min';

export enum Brand {
  FOX = 'fox',
  SPORTS = 'sports',
  NATION = 'nation',
}

export enum ENVS {
  PRODUCTION = 'production',
  STAGING = 'staging',
  QA = 'qa',
}

export enum DataDogService {
  FOX = 'fox-now-ssa',
  SPORTS = 'fox-sports-ssa',
  NATION = 'fox-nation-web',
}

export enum ExternalBrand {
  FOX = 'foxnow',
  NATION = 'foxnation',
  SPORTS = 'foxsports',
}

export enum ExternalENVFile {
  PRODUCTION = 'info.json',
  STAGING = 'info-staging.json',
  QA = 'info-qa.json',
}

export const LINKS = {
  HELP_LINK:
    process.env.NEXT_PUBLIC_BRAND === Brand.NATION
      ? 'https://help.fox.com/fox/s/topic/0TO1H000000HHQUWA4/fox-nation'
      : 'https://help.fox.com/s/topic/0TO1H000000HHQWWA4/fox-now',
  TERMS_LINK:
    process.env.NEXT_PUBLIC_BRAND === Brand.NATION
      ? 'https://nation.foxnews.com/terms-of-use '
      : 'https://www.fox.com/article/terms-of-use-597bc239ef528f0026dc0316/',
  DO_NOT_SELL_LINK:
    process.env.NEXT_PUBLIC_BRAND === Brand.NATION
      ? 'https://nation.foxnews.com/article/do-not-sell-5df81e0a23fb8600186ecc17/'
      : 'https://www.fox.com/article/do-not-sell-5df81e0a23fb8600186ecc17/',
  PRIVACY_POLICY:
    process.env.NEXT_PUBLIC_BRAND === Brand.NATION
      ? 'https://nation.foxnews.com/privacy-policy'
      : 'https://www.fox.com/article/privacy-policy-597bbe4f6603660022fa8689',
  target: '_blank',
};
