import { useEffect, useState } from 'react';
import { DefaultTheme } from 'styled-components';
import brandTheme from 'styles/theme';
import { config } from '../utils';

export const useTheme = (): {
  theme: DefaultTheme;
  themeLoaded: boolean;
  setBrand: (brand: string) => void;
} => {
  const [theme, setTheme] = useState<DefaultTheme>({} as DefaultTheme);
  const [themeLoaded, setThemeLoaded] = useState(false);

  const getBrand = (): string => config.BRAND || (process.env.NEXT_PUBLIC_BRAND as string);
  const brand = getBrand();

  const setBrand = (brand: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTheme(brandTheme[brand]);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTheme(brandTheme[brand]);
    setThemeLoaded(true);
  }, [brand, theme]);

  return { theme, themeLoaded, setBrand };
};
