import React, { FC } from 'react';

const FoxLogo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 242 50">
      <g id="logo__x28_W240_X_H240_x29_">
        <path d="m226.9 2.2-5.7 28-5.4-28h-12.5L198 28.9l-5.3-26.7h-14.2l.9 3.4c5.8 4.5 9.5 11.5 9.5 19.4 0 3.2-.6 6.2-1.7 9l3.8 13.9h13.4l5.3-27.9 4.6 27.8h13.9L241 2.2h-14.1zM139.8 25.1c0-5.9 2.1-11.4 5.6-15.7V2.2h-13.7l.2 24.8-13.3-24.8h-7.3l-6.9 12.2v21.3l6.9 12.1h6.2l.2-24.6 13.6 24.6h14.2v-7.1c-3.6-4.2-5.7-9.7-5.7-15.6" />
        <path d="M109 2.2H94.2L88.7 12l-5.6-9.8H68.3l12.9 22.9-12.9 22.8h14.9l5.5-9.7 5.4 9.7H109L96.1 25.1z" />
        <path d="M75.2 25.1C75.2 12.4 65 2.2 52.4 2.2 39.8 2.2 29.6 12.4 29.6 25c0 12.6 10.2 22.8 22.8 22.8 12.6.1 22.8-10.1 22.8-22.7m-18.8 9.3c0 2.1-1.9 3.8-3.9 3.8-2.1 0-3.8-1.7-3.8-3.9V15.6c0-2.1 1.7-4.1 3.8-4.1 2.1 0 3.9 1.9 3.9 4.1v18.8zM14.8 14.6h15.4l-.8-12.5H1v45.6h13.8V33h12.6V20.5H14.8zM164.4 2.2c-12.6 0-22.8 10.2-22.8 22.8 0 12.6 10.2 22.8 22.8 22.8 12.6 0 22.8-10.2 22.8-22.8 0-12.6-10.2-22.8-22.8-22.8" />
      </g>
    </svg>
  );
};

export default FoxLogo;
