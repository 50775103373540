import React, { FC } from 'react';

const NationLogo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 108" xmlSpace="preserve">
      <path fillRule="evenodd" clipRule="evenodd" fill="#fff" d="M-11.6-7.4h139.8v126.1H-11.6z" />
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M76.9 66c5.2 0 9.9 3.8 9.9 9.2 0 5.8-4.2 9.9-9.9 9.9-5.8 0-9.9-4.1-9.9-9.9 0-5.4 4.7-9.2 9.9-9.2zm-63.3.6 8.5 11v-11h4.7v18h-4.7l-8.5-11v11H8.9v-18h4.7zm27.2 0 6.8 18h-5l-1.2-3.1H35l-1.2 3.1h-4.9l6.9-18h5zm17.4 0v4h-3.9v14h-4.7v-14h-3.9v-4h12.5zm7 0v18h-4.7v-18h4.7zm28 0 8.5 11v-11h4.7v18h-4.7l-8.5-11v11h-4.7v-18h4.7zM77 69l-1.5 4.3-4.6.1 3.7 2.8-1.3 4.4 3.8-2.6 3.8 2.6-1.3-4.4 3.7-2.8-4.6-.1L77 69zm-38.7 3.1-2 5.7h3.9l-1.9-5.7zm16.2-55.9c11.4 0 20.6 9.1 20.6 20.3s-9.2 20.3-20.6 20.3-20.6-9.1-20.6-20.3 9.2-20.3 20.6-20.3zm-20.7-.1.8 11h-14v5.3H32v11H20.4v13H8.7V16.1h25.1zm48.8.1 5 8.4 4.8-8.4h12.9L94 35.4l12.5 20.9h-13l-5.9-9.9-5.8 9.9H69.1l12-20.7-11.5-19.4h13zm-28.2 7.7c-2 0-3.6 1.7-3.6 3.7v17.1c0 2 1.6 3.5 3.6 3.5s3.6-1.5 3.6-3.5l.1-17.1c.1-1.9-1.7-3.7-3.7-3.7z"
          fill="#263d76"
        />
        <path
          d="M106.5 95.6v4.5H8.7v-4.5h97.8zm0-7.8v4.5H8.7v-4.5h97.8zm0-28.3V64H8.7v-4.5h97.8zm0-50.8v4.5H8.7V8.7h97.8z"
          fill="#bc2433"
        />
      </g>
    </svg>
  );
};

export default NationLogo;
