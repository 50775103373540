import * as snippet from '@segment/snippet';
import { inject } from '../asyncScriptLoader';
import queue from '../queue';
import { SegmentPayload } from '../types';
import { analyticsConfig } from '../../utils';
import { trimLowerCase } from '../../utils';
import { profileConfig } from 'lib/utils/configs';

export enum EventTypes {
  PAGE = 'page',
  TRACK = 'track',
}

const scriptSrc = ` !function(){var analytics=window.analytics=window.analytics||[];
  if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");
  else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];
  analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";
  t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="YOUR_WRITE_KEY";analytics.SNIPPET_VERSION="4.13.2";
  analytics.load("${analyticsConfig.segmentKey}");
  analytics.page();
  analytics.ready(segmentFlushQueue);
  }}();`;

export async function init(): Promise<unknown> {
  await retry();
  return inject('', scriptSrc);
}

export const renderSnippet = (): string => {
  const opts = {
    apiKey: analyticsConfig.segmentKey as string,
    page: false,
  };

  if (profileConfig?.ENVIRONMENT === 'development') {
    return snippet.max(opts as AnyObject);
  }

  return snippet.min(opts as AnyObject);
};

const wait = (delay: number) => new Promise((resolve) => setTimeout(() => resolve(true), delay));

export const retry = async (attempts = 0, delay = 1500): Promise<unknown> => {
  await wait(delay);
  if (isLoaded()) return true;
  if (attempts >= 2) return true;
  inject('', scriptSrc);
  attempts += 1;
  return retry(attempts, delay);
};

export const isLoaded = (): boolean => !!window.analytics;

export const flushQueue = (): void => {
  const tracksStorage = queue.getAll() ?? [];
  tracksStorage.forEach((item: SegmentPayload) => {
    fireEvent(item);
    queue.remove();
  });
};

export const fireEvent = ({
  eventName = '',
  props = {},
  context = {},
  eventType = 'track',
}: SegmentPayload): void => {
  if (trimLowerCase(eventType) === EventTypes.PAGE) {
    window.analytics && window.analytics.page(eventName, props, context);
  } else if (trimLowerCase(eventType) === EventTypes.TRACK)
    window.analytics && window.analytics.track(eventName, props, context);
};

export const segmentTrackEvent = ({
  eventName,
  props,
  context,
  eventType,
}: SegmentPayload): void => {
  fireEvent({
    eventName,
    props,
    eventType,
    context,
  });
};
