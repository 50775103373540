import { setAccountCookie } from 'lib/utils/helper';
import { Brand } from 'constants/index';
import { Profile, RefreshTokenResponse } from 'lib/types/profileType';

const refreshToken = async (
  profile: Profile,
  account: AnyObject,
): Promise<undefined | null | RefreshTokenResponse> => {
  if (process.env.NEXT_PUBLIC_BRAND !== Brand.NATION || !account) return null;
  let refreshTokenResponse: AnyObject | undefined;

  profile.setAccessToken({ accessToken: account?.accessToken });

  if (account?.refreshToken) {
    try {
      refreshTokenResponse = await profile?.checkRefreshToken({
        refreshToken: account?.refreshToken,
        tokenExpiration: account?.tokenExpiration,
      });
    } catch (error) {
      refreshTokenResponse = await profile?.refreshToken({ refreshToken: account?.refreshToken });
    }
  }

  const newAccessToken = refreshTokenResponse?.accessToken;

  if (newAccessToken) {
    const newAuthData = { ...account, ...refreshTokenResponse };
    setAccountCookie({ ...newAuthData, idToken: '' });
    return newAuthData;
  }
};

export default refreshToken;
