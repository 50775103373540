import { Cookies } from 'react-cookie';
import { JwtPayload } from 'jwt-decode';
import { Profile } from '../types/profileType';

import {
  ACCOUNT_COOKIE_NAME_FOX,
  ACTIVATE_CODE_EXPIRES_MINUTES,
  ENVS,
  ROUTE_ACCOUNT_NATION,
  ROUTE_HOME,
} from 'constants/index';

// native Classnames or clsx
export const ts = (...args: (string | false | undefined)[][]): string => {
  let template: (string | false | undefined)[] = [];
  args.forEach((arg) => {
    if (arg.join()) {
      template = [...template, ...arg];
      return;
    }

    const entries = Object.entries(arg);
    if (entries.length) {
      entries.forEach((entry) => {
        if (entry[1]) template.push(entry[0]);
      });
    }
  });
  return template.join(' ');
};

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const setConfigToProfile = async (
  profile: Profile,
  newConfig: {
    appVersion: string;
    appName: string;
    accessToken: string;
  },
): Promise<Profile | undefined> => {
  try {
    const { ProfileConfig, Profile } = await import('@foxcorp/foxkit-profile-js');
    const config = await new ProfileConfig({ ...profile?.config, ...newConfig });
    return await new Profile(config);
  } catch (err) {
    console.log(err);
  }
};

export const tokenValid = (token: JwtPayload = {}): boolean | void => {
  const { iat, exp } = token;
  const now = Date.now() / 1000;
  if (iat && exp) {
    const expiry = iat + exp - 60;
    return now < expiry || iat !== exp;
  }
};

export const cleanObject = (obj: AnyObject = {}): AnyObject =>
  Object.entries(obj).reduce((newObj, [key, value = null]) => {
    if (
      typeof value !== 'string' &&
      !Array.isArray(value) &&
      Object.keys((value as AnyObject) ?? {}).length
    ) {
      return {
        ...newObj,
        [key]: cleanObject(value as AnyObject),
      };
    }

    if ([0, false].includes(value as boolean | number) || value) {
      return {
        ...newObj,
        [key]: value,
      };
    }

    return newObj;
  }, {});

export const setAccountCookie = (value?: AnyObject): AnyObject => {
  const cookies = new Cookies();
  const now = new Date().getTime();
  const expires = new Date(now + ACTIVATE_CODE_EXPIRES_MINUTES * 60000);
  const domainName = window?.location?.hostname?.match(/([a-z0-9-]*?)\.[a-z]{2,}$/)?.[1];

  cookies.set(ACCOUNT_COOKIE_NAME_FOX, value, {
    path: ROUTE_HOME,
    domain: `.${domainName}.com`,
    expires,
  });
};

export const displayNationAccountEnvURL = (): string => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === ENVS.PRODUCTION) {
    return `https://nation.foxnews.com${ROUTE_ACCOUNT_NATION}`;
  }

  return `https://${process.env.NEXT_PUBLIC_ENVIRONMENT}-nation.foxnews.com/account/signin/tv/`;
};
